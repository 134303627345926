<template>
  <VueContentHolder id="divProfileWrapper">
    <transition name="fade">
      <div v-show="isLoaded">
        <div class="profile-header-container">
          <div class="profile-header-left">
            <div class="profile-picture-holder">
              <VueImageEditor
                v-if="photoEditMode"
                :source.sync="formModel.photo"
                :cropperOptions="{
                  viewMode: 0,
                  autoCrop: true,
                  dragMode: 'move',
                  background: false,
                  zoomOnTouch: false,
                  zoomOnWheel: false,
                  cropBoxResizable: true,
                  aspectRatio: 1 / 1,
                }"
              />
              <img alt="profile-pic" :src="`${profilePicture}`" id="previewImg" />
            </div>
            <span
              v-if="photoEditMode"
              class="photo-edit"
              :class="{ 'photo-edit-empty ': !croppedImage || !croppedImage.length }"
            >
              <VueIcon iconName="IconCamera" iconColor="#ffffff"></VueIcon>
              <VueText sizeLevel="2" color="white-100">Değiştir</VueText>
            </span>
            <div class="customer-profile-info">
              <VueText class="customer-name">
                {{
                  (this.formModel.name ? this.formModel.name : this.nameBeforeChange) +
                    ' ' +
                    (this.formModel.surname ? this.formModel.surname : this.surnameBeforeChange)
                }}
              </VueText>
              <div class="customer-code-container">
                <VueText class="customer-code-key">Müşteri Kodunuz:</VueText>
                <VueText class="customer-code-value">{{ formModel.customerCode }}</VueText>
              </div>
            </div>
          </div>
          <div class="profile-header-right">
            <span @click="editProfile" class="edit-button">{{
              photoEditMode ? 'Vazgeç' : 'Profil Düzenle'
            }}</span>
          </div>
        </div>

        <QRCode />

        <Rosettes v-if="!getUserModel.isImageRecognitionUser" />

        <VueForm :bodyPadding="['0', '20']" ref="form">
          <div style="display:flex; align-items: center">
            <VueText class="profile-process-bar"
              >Profil Doluluğu %{{ Math.round(profileRate.toFixed(1)) }}</VueText
            >
            <div class="profile-tooltip">
              <VueIcon
                :iconName="constants.icons.info.name"
                :iconColor="'#79838e'"
                style="margin-left:5px; width: 14px; height: 14px"
              />
              <div class="bottom">
                <h6>Eksik alanlar!</h6>
                <ul v-for="(key, i) in nullKeys" :key="i">
                  <li>{{ convertToKeys(key) }}</li>
                </ul>
                <i></i>
              </div>
            </div>
          </div>
          <div class="meter">
            <span :style="{ width: `${profileRate}%` }" />
          </div>
          <VueInput
            name="name"
            id="firstname"
            type="text"
            placeholder="Adınız"
            v-model="formModel.name"
            :messageOnTop="true"
            :readonly="!photoEditMode"
            :disabled="!photoEditMode"
            validationRules="required|alpha_spaces"
            validatorName="Kullanıcı Adı"
          ></VueInput>
          <VueSpacer level="10" />
          <VueInput
            name="surname"
            id="lastname"
            type="text"
            placeholder="Soyadınız"
            v-model="formModel.surname"
            :messageOnTop="true"
            :readonly="!photoEditMode"
            :disabled="!photoEditMode"
            validationRules="required|alpha_spaces"
            validatorName="Kullanıcı Soyadı"
          ></VueInput>
          <VueSpacer level="10" />
          <VueInput
            name="phoneNumber"
            id="phoneNumber"
            type="text"
            placeholder="Telefon No"
            v-model="phoneNumber"
            :messageOnTop="true"
            :readonly="true"
            :disabled="true"
            validationRules="numeric|max:10|min:10"
            validatorName="Telefon Numarası"
          ></VueInput>
          <VueSpacer level="10" />
          <transition name="fade">
            <div v-if="formModel.kvkk">
              <VueInput
                name="email"
                id="email"
                type="text"
                placeholder="E-Posta"
                v-model="formModel.email"
                :messageOnTop="true"
                :readonly="!photoEditMode"
                :disabled="!photoEditMode"
                validationRules="email"
                validatorName="Kullanıcı E-Posta"
              ></VueInput>
              <VueSpacer level="10" />
              <VueInput
                name="gender"
                id="gender"
                type="text"
                placeholder="Cinsiyetiniz"
                v-model="selectedGender"
                :messageOnTop="true"
                :readonly="!photoEditMode"
                :disabled="!photoEditMode"
                validationRules="alpha_spaces"
                validatorName="Cinsiyet"
                @click="selectGender()"
              />
              <VueSpacer level="10" />
              <!-- <VueInput
                v-model="selectedMaritalStatus"
                @click="selectMaritalStatus()"
                :messageOnTop="true"
                :readonly="!photoEditMode"
                :disabled="!photoEditMode"
                placeholder="Medeni Durumu"
                validationRules="alpha_spaces"
                validatorName="Medeni Durumu"
                name="maritalStatus"
                id="marital"
                type="text"
              ></VueInput> -->
              <VueSpacer level="10" />
              <VueInput
                name="cityName"
                id="hometown"
                type="text"
                placeholder="Memleket"
                v-model="formModel.city.cityName"
                :messageOnTop="true"
                :readonly="!photoEditMode"
                :disabled="!photoEditMode"
                validationRules="alpha"
                validatorName="Memleket"
              ></VueInput>
              <div class="hidden-select-capsule" :hidden="!photoEditMode">
                <select id="hiddenCity" class="hidden-select" @input="changeHiddenCity">
                  <option v-for="(item, index) in cities" :key="index" :value="index + 1">{{
                    item
                  }}</option>
                </select>
              </div>
              <VueSpacer level="10" />
              <span v-if="birthDateMessageShow" class="birth-date-message"
                >Doğum Tarihi alanı boş bırakılamaz</span
              >
              <BrandDatePicker
                class="input-item birthdate-picker-wrapper"
                ref="birthDatePicker"
                format="DD.MM.YYYY"
                :startYear="dateOptions.year"
                :startMonth="dateOptions.month"
                v-model="formModel.birthDate"
                :isDateDisabled="birthDateValidator"
              >
                <div
                  class="picker-slot-container"
                  :class="[!photoEditMode ? 'picker-disabled' : '']"
                  @click="toggleDatePicker"
                >
                  <label class="picker-placeholder">Doğum Tarihi</label>
                  <div class="picker-content">
                    <VueText sizeLevel="7">{{ formModel.birthDate }}</VueText>
                  </div>
                  <div class="picker-toggle">
                    <VueIcon
                      :iconName="constants.icons.chevronDown.name"
                      :width="constants.icons.chevronDown.width"
                      :height="constants.icons.chevronDown.height"
                    ></VueIcon>
                  </div>
                </div>
              </BrandDatePicker>
            </div>
          </transition>
          <VueSpacer level="10" />
          <div :class="[!photoEditMode ? 'kvkk-disabled' : 'kvkk-button-container']">
            <VueText :color="!photoEditMode ? 'grey-30' : 'grey-40'" sizeLevel="5" weightLevel="2"
              >KVKK İzni</VueText
            >
            <BrandButton
              :disabled="!photoEditMode"
              @click="toggleKVKK()"
              iconColor="#ffffff"
              shape="rounded"
              :radius="50"
              class="kvkk-out-button"
              >{{ formModel.kvkk ? 'Geri Al' : 'İzin Ver' }}</BrandButton
            >
          </div>
          <VueSpacer level="10" />
          <div class="user-privacy">
            <VueText sizeLevel="4" weightLevel="2" color="grey-50"
              ><u @click="showPrivacyNotif()">Gizlilik bildirimini</u>
              okumak için tıklayınız
            </VueText>
          </div>
          <div class="user-contact-pref" :class="[!photoEditMode ? 'text-disabled' : '']">
            <div><VueText sizeLevel="5" weightLevel="2">İleti Yönetimi</VueText></div>
            <div class="user-contact-pref-check">
              <div class="user-contact-pref-check-sms">
                <VueText sizeLevel="5" weightLevel="2">SMS</VueText>
                <VueCheck
                  :disabled="!photoEditMode"
                  id="chkSms"
                  v-model="formModel.autoSmsPermission"
                ></VueCheck>
              </div>
              <div class="user-contact-pref-check-tel">
                <VueText sizeLevel="5" weightLevel="2">Telefon</VueText>
                <VueCheck
                  :disabled="!photoEditMode"
                  id="chkCall"
                  v-model="formModel.autoCallPermission"
                ></VueCheck>
              </div>
            </div>
          </div>
          <VueSpacer level="10" />
          <div class="user-contract">
            <div class="user-contract-check">
              <VueCheck
                :disabled="!photoEditMode"
                v-if="isContractEnabled"
                id="chkContract"
                v-model="formModel.readContract"
              ></VueCheck>
              <VueText sizeLevel="4" weightLevel="2" color="grey-50"
                ><u @click="showContract()">Sözleşmeyi</u>
                {{ isContractEnabled ? 'okudum ve onaylıyorum' : 'okumak için tıklayınız' }}
              </VueText>
            </div>
            <div v-if="validateForm && !formModel.readContract" class="user-contract-validation">
              <VueText sizeLevel="4" weightLevel="2" color="red-30"
                >Lütfen sözleşmeyi onaylayın.</VueText
              >
            </div>
          </div>
          <VueSpacer v-if="isContractEnabled" level="10" />
          <VueSpacer level="20" />
        </VueForm>
        <VueContentHolder :padding="['0']" class="profile-bottom-container">
          <VueText color="grey-40" sizeLevel="6" weightLevel="3" class="company-info-header"
            >Firma Bilgileri</VueText
          >

          <VueContentHolder :padding="['20', '20']" class="company-info">
            <VueText class="company-sub-title" color="grey-30" sizeLevel="3">Firma Adı:</VueText>
            <VueText color="grey-40" sizeLevel="6">{{ formModel.customerName }}</VueText>
          </VueContentHolder>
          <VueContentHolder :padding="['20', '20']" class="company-address">
            <VueText class="company-sub-title" color="grey-30" sizeLevel="3">Adres:</VueText>
            <VueText color="grey-40" sizeLevel="6">{{ formModel.customerAddress }}</VueText>
          </VueContentHolder>
        </VueContentHolder>
        <VueSpacer level="5" />
        <div class="weekly-wrapper">
          <VueText class="info-text" :color="'grey-30'" sizeLevel="4" weightLevel="1"
            >Ürün ön siparişlerinizi verebileceğiniz günleri ve rut düzeninize ait detayları
            aşağıdaki Ziyaret Günlerim/Ön Sipariş Günlerim başlıklarına tıklayarak
            görebilirsiniz</VueText
          >
          <div class="tabs">
            <div>
              <BrandButton
                @click="setActiveTab('visitDays')"
                shape="rounded"
                :radius="50"
                :size="sizes.xSmall"
                class="tab-btn"
                :outlined="activeTab !== 'visitDays'"
                ><VueText weightLevel="4" sizeLevel="3">ZİYARET GÜNLERİM</VueText></BrandButton
              >
            </div>
            <div>
              <BrandButton
                @click="setActiveTab('orderDays')"
                :size="sizes.xSmall"
                shape="rounded"
                :radius="50"
                :outlined="activeTab !== 'orderDays'"
                class="tab-btn"
                ><VueText weightLevel="4" sizeLevel="3">ÖN SİPARİŞ GÜNLERİM</VueText></BrandButton
              >
            </div>
          </div>
          <div class="days-table" v-for="(partner, i) in partners" :key="i">
            <img class="partner-logo" :src="partner.partnerImage" :alt="partner.partnerName" />
            <div class="day" v-for="(day, index) in data.days" :key="index">
              <div class="day-name">
                {{
                  day === 'Pazartesi'
                    ? 'Pzt'
                    : day === 'Salı'
                    ? 'Sal'
                    : day === 'Çarşamba'
                    ? 'Çar'
                    : day === 'Perşembe'
                    ? 'Per'
                    : day === 'Cuma'
                    ? 'Cum'
                    : day === 'Cumartesi'
                    ? 'Cmt'
                    : day === 'Pazar'
                    ? 'Paz'
                    : ''
                }}
              </div>
              <div class="day-check">
                <VueIcon
                  v-if="isChecked(day, partner)"
                  :iconName="constants.icons.iconChecked.name"
                  :width="constants.icons.iconChecked.width"
                  :height="constants.icons.iconChecked.height"
                />
              </div>
            </div>
          </div>
        </div>

        <VueSpacer level="40" />

        <div v-if="!getBottomBarStatus">
          <BrandButton
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :disabled="!isProfileChanged"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleSubmit"
            >GÜNCELLE</BrandButton
          >
        </div>
      </div>
    </transition>
    <BrandSelectModal
      :showModal="showSelectGenderModal"
      :title="selectGenderModalConfig.title"
      @click="getSelectedGender()"
      @onModalClose="showSelectGenderModal = false"
    >
      <VueContentHolder :padding="['0', '20']" :key="selectedGenderIndex">
        <VueListItem
          @click="onRowSelected(item.text, index, item.id)"
          :text="item.text"
          v-for="(item, index) in selectGenderModalConfig.modalBody"
          :key="item.id"
          :icon="getIcon(item.icon, index)"
          :contentAlignment="constants.flexAlignment.between"
        ></VueListItem>
      </VueContentHolder>
    </BrandSelectModal>

    <!-- <BrandSelectModal
      :showModal="showSelectMaritalStatusModal"
      :title="selectMaritalStatusModalConfig.title"
      @click="getSelectedMaritalStatus()"
      @onModalClose="showSelectMaritalStatusModal = false"
    >
      <VueContentHolder :padding="['0', '20']" :key="selectedMaritalStatusIndex">
        <VueListItem
          @click="onRowMaritalStatusSelected(item.text, index, item.id)"
          :text="item.text"
          v-for="(item, index) in selectMaritalStatusModalConfig.modalBody"
          :key="item.id"
          :icon="getMaritalStatusIcon(item.icon, index)"
          :contentAlignment="constants.flexAlignment.between"
        ></VueListItem>
      </VueContentHolder>
    </BrandSelectModal> -->

    <BrandPickerModal
      :key="renderKey"
      :showModal="showCityPickerModal"
      @click="cancelCitySelection"
      @getText="getCity"
      :textArray="cities"
      :textPicker="true"
    >
    </BrandPickerModal>

    <BrandSelectModal
      :showModal="isDataPolicyModalOpened"
      :title="'Gizlilik Bildirimi'"
      :plainTextModal="true"
      @click="isDataPolicyModalOpened = false"
      @onModalClose="isDataPolicyModalOpened = false"
    >
      <VueContentHolder :padding="[20, 20, 80, 20]">
        <div v-html="dataPolicyHtml"></div>
      </VueContentHolder>
    </BrandSelectModal>

    <BrandSelectModal
      :showModal="showContractModal"
      :plainTextModal="true"
      title="Kullanıcı Sözleşmesi"
      @click="showContractModal = false"
      @onModalClose="showContractModal = false"
    >
      <div class="static-desc" v-html="contractContent"></div
    ></BrandSelectModal>
  </VueContentHolder>
</template>

<script>
import BrandDatePicker from '@/components/brand/BrandDatePicker/BrandDatePicker.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import BrandPickerModal from '@/components/brand/Modals/BrandModal/BrandPickerModal.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { mapGetters } from 'vuex';
import { Account, Common, User } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';
import StorageProps from '@/mixins/storageProps.js';
import VueCheck from '@/components/shared/VueCheck/VueCheck.vue';
import StaticContent from '@/services/Api/staticContents';
import VueImageEditor from '@/components/VueImageEditor/VueImageEditor.vue';
import dateUtils from '@/mixins/dateUtils';
import Rosettes from '@/views/pages/secure/settings/Rosettes.vue';
import QRCode from '@/views/pages/secure/settings/QRCode.vue';

export default {
  name: 'Profile',
  mixins: [StorageProps, dateUtils],
  data() {
    return {
      nullKeys: [],
      isLoaded: false,
      isDataPolicyModalOpened: false,
      dataPolicyHtml: null,
      showContractModal: false,
      contractContent: '',
      isContractEnabled: false,
      validateForm: false,
      cities: [],
      renderKey: 99,
      isProfileChanged: false,
      birthDateMessageShow: false,
      initialFormValues: {
        city: {
          cityId: 1,
          cityName: 'Adana',
        },
      },
      phoneNumber: '',
      formModelCurrent: null,
      formModel: {
        birthDate: null,
        city: {
          cityId: 0,
          cityName: '',
        },
        customerAddress: '',
        customerCode: '',
        customerName: '',
        email: '',
        gender: '',
        kvkk: false,
        name: '',
        phoneNumber: '',
        photo: '',
        readContract: false,
        autoCallPermission: false,
        autoSmsPermission: false,
        surname: '',
        //  maritalStatus: '',
      },
      nameBeforeChange: '',
      surnameBeforeChange: '',
      croppedImage: '',
      submitted: false,
      photoEditMode: false,
      showSelectGenderModal: false,
      //  showSelectMaritalStatusModal: false,
      showPickerModal: false,
      showCityPickerModal: false,
      selectedGender: null,
      selectedGenderIndex: '',
      // selectedMaritalStatus: null,
      // selectedMaritalStatusIndex: '',
      selectGenderModalConfig: {
        title: 'Cinsiyet',
        modalBody: [],
      },
      // selectMaritalStatusModalConfig: {
      //   title: 'Medeni Durumu',
      //   modalBody: [
      //     {
      //       id: 1,
      //       text: 'Bekar',
      //       icon: ICON_VARIABLES.circle,
      //     },
      //     {
      //       id: 2,
      //       text: 'Evli ve Çocuklu',
      //       icon: ICON_VARIABLES.circle,
      //     },
      //     {
      //       id: 3,
      //       text: 'Evli ve Çocuksuz',
      //       icon: ICON_VARIABLES.circle,
      //     },
      //   ],
      // },
      activeTab: 'visitDays',
      data: {
        days: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
      },
      profileRate: 0,
      partners: [],
    };
  },
  watch: {
    formModel: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.photo) {
          this.croppedImage = newVal.photo;
        }
        this.isProfileChanged = true;
      },
    },
  },
  beforeCreate() {
    Common.getGenders().then(res => {
      if (res.data && res.data.Data && res.data.Data.genders) {
        let genders = res.data.Data.genders;
        genders.map(gender => {
          this.selectGenderModalConfig.modalBody.push({
            id: gender.Key,
            text: gender.Value,
            icon: ICON_VARIABLES.circle,
          });
        });
      }
    });

    Common.getCities()
      .then(res => {
        let cities = res.data.Data.cities;
        if (res && cities) {
          cities.map(city => {
            this.cities.push(city.cityName);
          });
        }
      })
      .then(() => this.renderKey++);
  },
  created() {
    this.getProfileData();
    this.getRouteDays();
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  components: {
    VueInput,
    VueContentHolder,
    VueText,
    VueForm,
    VueSpacer,
    BrandButton,
    VueIcon,
    VueListItem,
    BrandSelectModal,
    BrandPickerModal,
    VueCheck,
    VueImageEditor,
    BrandDatePicker,
    Rosettes,
    QRCode,
  },
  computed: {
    ...mapGetters('auth', ['getUserModel']),
    dateOptions() {
      let currentDate = new Date();
      let validDate = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate(),
      );
      return { month: validDate.getMonth(), year: validDate.getFullYear() };
    },
    profilePicture() {
      const currUserPic = this.currentUser ? this.currentUser.picture : null;

      const photo =
        this.photoEditMode && this.croppedImage
          ? this.croppedImage
          : this.formModel.photo || currUserPic || process.env.VUE_APP_USER_PHOTO_PLACEHOLDER;

      return photo || process.env.VUE_APP_USER_PHOTO_PLACEHOLDER;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: ICON_VARIABLES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    ...mapGetters('app', ['getBottomBarStatus']),
  },
  methods: {
    convertToKeys(key) {
      switch (key) {
        case 'Name':
          return 'Ad';
        case 'surname':
          return 'Soyad';
        case 'phoneNumber':
          return 'Telefon No';
        case 'email':
          return 'E-Posta';
        case 'gender':
          return 'Cinsiyet';
        // case 'maritalStatus':
        //   return 'Medeni Durumu';
        case 'birthDate':
          return 'Doğum Tarihi';
        case 'kvkk':
          return 'KVKK';
        case 'cityName':
          return 'Memleket';
        case 'Photo':
          return 'Profile Fotoğrafı';
      }
      return key;
    },
    getRouteDays() {
      User.getRouteDays()
        .then(res => {
          if (res.data && res.data.Data) {
            this.partners = res.data.Data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    birthDateValidator: date => {
      let currentDate = new Date();
      let validDate = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate(),
      );
      return date > validDate;
    },
    toggleDatePicker() {
      if (!this.photoEditMode) return;
      this.$refs.birthDatePicker.$children[0].toggle();
    },
    toggleKVKK() {
      this.formModel.kvkk = !this.formModel.kvkk;
    },
    showPrivacyNotif() {
      StaticContent.getStaticContent('VeriPolitikalari').then(res => {
        if (res.data.Data && res.data.Data.content) {
          this.dataPolicyHtml = res.data.Data.content;
        }
      });
      this.isDataPolicyModalOpened = true;
    },
    showContract() {
      StaticContent.getStaticContent('TPSozlesmesi').then(res => {
        let {
          Data: { content },
        } = res.data;

        this.contractContent = content;
        this.showContractModal = true;
      });
    },
    editProfile() {
      this.setBottomBarStatus(!this.getBottomBarStatus);
      if (!this.getBottomBarStatus) {
        this.croppedImage = '';
      }
      if (!this.photoEditMode) {
        this.formModel = { ...this.formModelCurrent };
      }
      if (this.birthDateMessageShow) {
        this.birthDateMessageShow = false;
      }
    },
    getIcon(itemIcon, index) {
      return index === this.selectedGenderIndex ? ICON_VARIABLES['checked'] : itemIcon;
    },
    // getMaritalStatusIcon(itemIcon, index) {
    //   return index === this.selectedMaritalStatusIndex ? ICON_VARIABLES['checked'] : itemIcon;
    // },
    getProfileData() {
      if (this.currentUser.id) {
        let userId = this.currentUser.id;

        Account.getProfileByUserId(userId)
          .then(res => {
            const user = res.data.Data.userProfile;

            let userInfo = {
              ...user,
              birthDate: user.birthDate ? this.parseISODate(user.birthDate) : null,
            };

            this.phoneNumber = userInfo.phoneNumber;

            const currentUser = new StorageHelper({
              id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY,
            }).get();

            const modifiedUser = {
              ...currentUser,
              name: userInfo.name,
              surname: userInfo.surname,
              picture: userInfo.photo,
            };

            new StorageHelper({
              id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY,
            }).set(modifiedUser);
            this.$store.dispatch('auth/setUserModel', modifiedUser);

            if (!user.city) {
              userInfo['city'] = { ...this.initialFormValues.city };
            }

            this.formModel = { ...userInfo };
            this.formModelCurrent = { ...userInfo };

            var profileRateData = {
              birthDate: userInfo.birthDate,
              cityName: userInfo.city.cityName,
              email: userInfo.email,
              gender: userInfo.gender,
              kvkk: userInfo.kvkk,
              // maritalStatus: userInfo.maritalStatus,
              name: userInfo.name,
              phoneNumber: userInfo.phoneNumber,
              photo: userInfo.photo,
              surname: userInfo.surname,
            };

            this.nameBeforeChange = userInfo.name;
            this.surnameBeforeChange = userInfo.surname;

            const profileRateDataValues = Object.values(profileRateData);
            const notNulls = [];

            profileRateDataValues.forEach(f => {
              if (f != null && f != '' && f.toString() != '0') {
                notNulls.push(f);
              }
            });

            Object.entries(profileRateData).forEach(([key, value]) => {
              if (value == null || value == '' || value.toString() == '0') {
                this.nullKeys.push(key);
              }
            });

            this.profileRate = (notNulls.length / profileRateDataValues.length) * 100;

            if (this.formModel.gender) {
              this.setSelectedGender(this.formModel.gender);
            }
            // if (this.formModel.maritalStatus) {
            //   this.setSelectedMaritalStatus(this.formModel.maritalStatus);
            // }

            if (!this.formModel.readContract) {
              this.isContractEnabled = true;
            }
          })
          .finally(() => {
            this.isLoaded = true;
          });
      }
    },
    async handleSubmit(e) {
      e.preventDefault();
      const isValid = await this.$refs.form.$refs.observer.validate();
      let birthDateValid = this.formModel.birthDate ? true : false;
      if (!isValid || !birthDateValid) {
        if (!birthDateValid) {
          this.birthDateMessageShow = !birthDateValid;
        }
        return;
      }

      this.validateForm = true;
      this.$refs.form.$refs.observer.validate().then(isValid => {
        if (isValid) {
          this.setBottomBarStatus(true);
          this.validateForm = false;
          this.submitted = true;

          let submittedForm = {
            userProfile: {
              ...this.formModel,
              birthDate: this.formModel.birthDate
                ? this.toISODateString(this.formModel.birthDate)
                : null,
            },
          };

          delete submittedForm.userProfile.phoneNumber;

          Account.updateProfile(submittedForm.userProfile).then(res => {
            if (res.data?.Data?.success) {
              this.isContractEnabled = !submittedForm.userProfile.readContract;
              if (res.data?.Data?.data?.agreementErrorMessage) {
                const errorModalConfig = {
                  isModalOpened: true,
                  modalText: `${res.data?.Data?.data?.agreementErrorMessage}`,
                  modalType: 'error',
                  alertType: 'error',
                  firstButtonText: 'KAPAT',
                };
                this.$store.dispatch('app/setModalConfig', errorModalConfig);
              }
              this.getProfileData();
            }
          });
        } else {
          return false;
        }
      });
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
      this.photoEditMode = !this.photoEditMode;
      this.isProfileChanged = false;
    },
    selectGender() {
      this.showSelectGenderModal = true;
      document.documentElement.style.overflow = 'hidden';
      document.getElementById('gender').blur();
      document.getElementsByTagName('main')[0].classList.add('pb-0');
    },
    getSelectedGender() {
      this.showSelectGenderModal = false;
      document.documentElement.style.overflow = 'auto';
      document.getElementsByTagName('main')[0].classList.remove('pb-0');
    },
    setSelectedGender(id) {
      let genderIndex = this.selectGenderModalConfig.modalBody.findIndex(item => item.id == id);

      if (genderIndex != -1) {
        this.selectedGender = this.selectGenderModalConfig.modalBody[genderIndex].text;

        this.selectedGenderIndex = genderIndex;
      }
    },
    onRowSelected(event, index, id) {
      if (index === this.selectedGenderIndex) return;

      this.selectedGender = event;
      this.formModel.gender = id.toString();
      this.selectedGenderIndex = index;
    },
    // selectMaritalStatus() {
    //   this.showSelectMaritalStatusModal = true;
    //   document.documentElement.style.overflow = 'hidden';
    //   document.getElementById('marital').blur();
    //   document.getElementsByTagName('main')[0].classList.add('pb-0');
    // },
    // getSelectedMaritalStatus() {
    //   this.showSelectMaritalStatusModal = false;
    //   document.documentElement.style.overflow = 'auto';
    //   document.getElementsByTagName('main')[0].classList.remove('pb-0');
    // },
    // setSelectedMaritalStatus(id) {
    //   let statusIndex = this.selectMaritalStatusModalConfig.modalBody.findIndex(
    //     item => item.id == id,
    //   );
    //   if (statusIndex != -1) {
    //     this.selectedMaritalStatus = this.selectMaritalStatusModalConfig.modalBody[
    //       statusIndex
    //     ].text;

    //     this.selectedMaritalStatusIndex = statusIndex;
    //   }
    // },
    // onRowMaritalStatusSelected(event, index, id) {
    //   if (index === this.selectedMaritalStatusIndex) return;

    //   this.selectedMaritalStatus = event;
    //   this.formModel.maritalStatus = id.toString();
    //   this.selectedMaritalStatusIndex = index;
    // },
    setCity() {
      this.showCityPickerModal = true;
    },
    getCity(value, id) {
      this.showCityPickerModal = false;
      this.formModel.city.cityName = value;
      this.formModel.city.cityId = id;
    },
    cancel() {
      this.showPickerModal = false;
      document.documentElement.style.overflow = 'auto';
    },
    cancelCitySelection() {
      this.showCityPickerModal = false;
      document.documentElement.style.overflow = 'auto';
    },
    changeHiddenCity(event) {
      this.formModel.city.cityName = event.currentTarget.selectedOptions[0].text;
      this.formModel.city.cityId = Number(event.currentTarget.selectedOptions[0].value);
    },
    setActiveTab(tab) {
      if (this.activeTab != tab) {
        this.activeTab = tab;
      }
    },
    isChecked(day, partner) {
      if (this.activeTab === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/_animations.scss';
@function asset($path) {
  @return url('~@/assets/#{$path}');
}

.profile-picture-holder {
  height: 70px;
  width: 70px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  pointer-events: none;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    max-height: 100%;
  }
}

// =======================

input#file {
  display: inline-block;
  width: 70px;
  height: 70px;
  padding: 70px 0 0 0;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: asset('profile-pic.png') no-repeat center center;
  border-radius: palette-radius-level('0');
  background-size: 70px 70px;
  position: relative;
}
input:disabled {
  color: palette-color-level('grey', '50');
}
.photo-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.photo-edit-empty {
    background-image: linear-gradient(to bottom, rgba(180, 194, 211, 0.6), #23303d);
  }
}

.photo-preview,
.photo-edit {
  position: absolute;
  border-radius: palette-radius-level('0');
  width: 70px;
  height: 70px;
  left: 11px;
}
.user-contact-pref {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  padding: palette-space-level(10) palette-space-level(10) palette-space-level(10)
    palette-space-level(20);
  &.text-disabled {
    color: palette-color-level('grey', 30);
  }

  &-check {
    div {
      &:first-child {
        padding-bottom: palette-space-level(10);
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        text-align: right;
        padding-right: palette-space-level(10);
      }
    }
  }
}
.user-privacy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
.user-contract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  &-check {
    display: flex;
    flex-direction: row;
  }
  &-validation {
    margin-top: 5px;
  }
}
.photo-preview {
  margin-top: -35px;
}
.kvkk-disabled {
  border: 1px solid palette-color-level('grey', '30');
}
.kvkk-button-container {
  border: 1px solid palette-color-level('grey', '40');
}
.kvkk-button-container,
.kvkk-disabled {
  display: flex;
  padding: palette-space-level('10') palette-space-level('10') palette-space-level('10')
    palette-space-level('20');
  border-radius: palette-radius-level('2');
  align-items: center;
  justify-content: space-between;
  width: 100%;
  button.kvkk-out-button {
    //TODO: BRANDBUTTON DÜZELTMELERİNDEN SONRA DEĞİŞECEK
    width: unset !important;
    padding: palette-space-level('5') palette-space-level('15') !important;
  }
}
.profile-header-container {
  display: flex;
  padding: palette-space-level('10');
  background-color: palette-color-level('grey', '10');
  margin-bottom: palette-space-level('20');
  border: 1px solid palette-color-level('grey', '15');
  justify-content: space-between;
  position: relative;
}
.profile-header-left,
.customer-code-container {
  display: flex;
  align-items: center;
}

.customer-profile-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: palette-space-level('10');

  .customer-code-container {
    white-space: nowrap;
    padding-top: palette-space-level('10');
  }

  .customer-name {
    font-size: palette-font-size-level('7');
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .customer-code-key {
    font-size: palette-font-size-level('3');
    color: palette-color-level('grey', '30');
  }

  .customer-code-value {
    font-size: palette-font-size-level('4');
    color: palette-color-level('grey', '40');
    padding-left: palette-space-level('5');
  }
}

.profile-header-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  right: 10px;

  .edit-button {
    border: 1px solid black;
    width: 90px;
    text-align: center;
    padding: palette-space-level('5');
    font-size: palette-font-size-level('3');
    white-space: nowrap;
  }
}

.profile-bottom-container {
  margin-bottom: palette-space-level('20');
  .company-info,
  .company-address {
    background-color: palette-color-level('grey', '10');
  }

  .company-info,
  .company-address {
    border-top: 0.5px solid rgba(palette-color-level('grey', '20'), 0.6);
  }
  .company-address {
    border-bottom: 0.5px solid rgba(palette-color-level('grey', '20'), 0.6);
  }

  .company-info-header {
    padding: palette-space-level('10') palette-space-level('20');
  }
  .company-sub-title {
    margin-bottom: palette-space-level('5');
  }
}
.hidden-select-capsule,
.hidden-date-capsule {
  position: relative;
  width: 100%;
  .hidden-select,
  .hidden-date {
    width: 100%;
    min-width: 97%;
    height: 58px;
    outline: none;
    position: absolute;
    left: 0;
    top: -58px;
    opacity: 0;
  }

  .hidden-date[type='date']::-webkit-calendar-picker-indicator {
    display: block;
    width: 100%;
    height: 58px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: yellow;
  }
}

.static-desc {
  max-height: 400px;
  overflow: auto;
  padding: palette-space-level(20);
}
.error-message {
  padding: palette-space-level(10);
}
.birth-date-message {
  display: block;
  height: $input-message-height;
  padding: $input-message-padding;
  position: relative;
  color: $input-error-color;
  font-size: $input-message-font-size;
  font-weight: $input-message-font-weight;
}
.birthdate-picker-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  height: 58px;
  .picker-placeholder {
    position: absolute;
    top: palette-space-level(10);
    left: palette-space-level(20);
    font-size: palette-font-size-level(3);
  }
  .picker-slot-container {
    border: 1px solid #23303d;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 25px 20px 10px 20px;
    justify-content: space-between;
    &.picker-disabled {
      opacity: 0.6;
    }
    .picker-content {
      display: flex;
      label {
        opacity: inherit;
        display: block;
        font-size: palette-font-size-level(6);
        font-weight: palette-font-weight-level(2);
        color: palette-color-level('grey', 40);
      }
    }
    .picker-toggle {
      width: 30px;
      .picker-clear {
        max-width: palette-space-level(10);
        margin-right: palette-space-level(10);
      }

      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 50%;
      right: palette-space-level(20);
      transform: translateY(-50%);
    }
  }
}
.tabs {
  display: flex;
  justify-content: center;
  margin-top: palette-space-level(25);
  margin-left: palette-space-level(50);
  z-index: 5;
  & > div {
    &:first-child {
      margin-right: palette-space-level(15);
      @media screen and (max-width: 325px) {
        margin-right: palette-space-level(10);
      }
    }
  }
  .tab-btn {
    padding: 5px 10px;
  }
}

.days-table {
  margin-top: palette-space-level(20);
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > div:nth-child(2) {
    border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);

  &-name {
    background-color: palette-color-level('grey', 10);
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
.info-text {
  padding-left: palette-space-level(20);
  padding-right: palette-space-level(20);
}
.weekly-wrapper {
  margin-bottom: palette-space-level(50);
}
.partner-logo {
  margin-top: 30px;
  width: 60px;
  padding-right: 3px;
  padding-left: 3px;
}
.profile-process-bar {
  display: inline-block;
  font-size: palette-font-size-level('3');
  color: palette-color-level('grey', '30');
}
.meter {
  box-sizing: content-box;
  height: 10px;
  position: relative;
  margin: 10px 0 20px 0;
  background: #d9d9d9;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px #d9d9d9;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #3aac5d;
  position: relative;
  overflow: hidden;
}
.profile-tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.profile-tooltip h6 {
  margin: 5px 0;
  font-weight: bold;
}

.profile-tooltip .bottom {
  min-width: 200px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 20px;
  color: #79838e;
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.profile-tooltip:hover .bottom {
  display: block;
}

.profile-tooltip .bottom img {
  width: 400px;
}

.profile-tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.profile-tooltip .bottom i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
</style>
