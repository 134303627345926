<template>
  <div class="modal-wrapper picker-modal" v-if="showModal">
    <div class="modal-header">
      <VueButton :ghost="true" as="a" @click="cancel()"
        ><VueText weightLevel="3" sizeLevel="4" color="white-100">Vazgeç</VueText></VueButton
      >
      <VueButton :ghost="true" as="a" @click="textPicker ? confirmText() : confirm()"
        ><VueText weightLevel="3" sizeLevel="4" color="white-100">Tamam</VueText></VueButton
      >
    </div>
    <div v-if="datePicker">
      <smooth-picker ref="smoothPicker" :data="dates" />
    </div>
    <div v-if="textPicker">
      <smooth-picker ref="smoothPicker" :data="textList" />
    </div>
  </div>
</template>

<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import 'vue-smooth-picker/dist/css/style.css';
import { SmoothPicker } from 'vue-smooth-picker';

export default {
  name: 'BrandPickerModal',
  components: { VueButton, VueText, SmoothPicker },
  props: {
    refreshData: {
      type: Boolean,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    datePicker: {
      type: Boolean,
      default: false,
    },
    textPicker: {
      type: Boolean,
      default: false,
    },
    textArray: {
      type: Array,
    },
    filterType: {
      type: String,
      default: 'year',
    },
    selectedDay: {},
    selectedMonth: {},
    selectedYear: {},
  },
  watch: {
    textArray: {
      handler(arr) {
        if (this.refreshData) this.textList[0].list = arr;
      },
    },
  },
  data() {
    return {
      dates: [],
      textList: [
        {
          currentIndex: 0,
          flex: 1,
          list: [],
          textAlign: 'center',
          className: 'item-group',
        },
      ],
    };
  },
  beforeMount() {
    if (this.textArray && this.textArray.length) {
      this.textList[0].list.push(...this.textArray);
    }

    const months = [...Array(12)].map((d, i) => i + 1);
    const days = [...Array(31)].map((d, i) => i + 1);
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 1923; i <= currentYear; i++) {
      years.push(i);
    }
    this.dates = [
      ...[
        {
          currentIndex: this.selectedDay - 1 || 0,
          flex: 3,
          list: days,
          textAlign: 'center',
          className: 'item-group',
        },
        {
          currentIndex: this.selectedMonth || 0,
          flex: 3,
          list: months,
          textAlign: 'center',
          className: 'item-group',
        },
        {
          currentIndex: parseInt(this.selectedYear - 1923) || parseInt((currentYear - 1923) / 2),
          flex: 3,
          list: years,
          textAlign: 'center',
          className: 'item-group',
        },
      ],
    ];
  },
  methods: {
    confirm() {
      const currentIndexList = this.$refs.smoothPicker.getCurrentIndexList();
      let day = this.dates[0].list[currentIndexList[0]];
      let month = this.dates[1].list[currentIndexList[1]];
      let year = this.dates[2].list[currentIndexList[2]];
      let date = new Date(year, month - 1, day);
      const convertedDate = date.toISOString();
      this.$emit('getDate', convertedDate);
    },
    confirmText() {
      const currentTextIndexList = this.$refs.smoothPicker.getCurrentIndexList();
      const selectedText = this.textList[0].list[currentTextIndexList[0]];
      let id = this.textList[0].list.indexOf(selectedText) + 1;
      this.$emit('getText', selectedText, id, this.filterType);
    },
    cancel(e) {
      this.$emit('click', e);
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.modal-wrapper {
  position: sticky;
  bottom: 0;
  z-index: 40;
  width: 100%;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: palette-space-level('15') palette-space-level('20');
  width: 100%;
  background-color: palette-color-level('grey', '40');
}
</style>
