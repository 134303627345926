<template>
  <div v-if="getRosetteVisibility">
    <div class="rosette-container">
      <div>
        <VueText sizeLevel="18" weightLevel="3">Aktif+ Görevlerim</VueText>
        <div class="rosette-box">
          <div v-for="(rosette, index) in getRosettesInfo" :key="index" class="rosette-item">
            <div @click="selectCurrentRosette(rosette)" class="circular-progress">
              <div class="progress-bar" :id="`progress-bar-${index}`">
                <img :src="`${rosette.rosetteImageUrl}`" alt="rosette" class="rosette-image" />
              </div>
              <VueText class="rosette-name" weightLevel="2">{{ rosette.rosetteName }}</VueText>
              <DynamicRosetteButton
                :rosette="rosette"
                :hasNpsSurvey="hasNpsSurvey"
                :hasActiveMarathons="hasActiveMarathons"
                @openNpsSurveyPopup="openNpsSurveyPopup"
                class="dynamic-rosette-button"
              />
            </div>
          </div>
        </div>
      </div>
      <BrandButton
        @click="redirectToRosetteDetail"
        shape="rounded"
        class="all-show-button"
        :radius="50"
      >
        <VueText :isSingleLine="true" color="white-100" sizeLevel="4" weightLevel="2">
          Tümünü Gör
        </VueText>
      </BrandButton>
      <BrandSurveyPopup style="align-items: center !important;" :show.sync="showRosetteModal">
        <div style="width: 1000px; padding: 20px">
          <RosettePopup :selectedRosette="selectedRosette" />
          <DynamicRosetteButton
            :rosette="selectedRosette"
            :hasNpsSurvey="hasNpsSurvey"
            :hasActiveMarathons="hasActiveMarathons"
            @openNpsSurveyPopup="openNpsSurveyPopup"
          />
        </div>
      </BrandSurveyPopup>
    </div>
    <NpsSurvey
      v-if="isNpsRosette"
      :npsSurveyId="npsSurveyId"
      :isRosetteNps="true"
      @closeModal="closeRosetteModal"
    />
  </div>
</template>

<script>
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import BrandSurveyPopup from '@/components/brand/Modals/BrandModal/BrandSurveyPopup.vue';
import RosettePopup from './RosettePopup.vue';
import RosetteInfo from '@/mixins/rosetteInfo.js';
import NpsSurvey from '@/views/pages/secure/NpsSurvey.vue';
import DynamicRosetteButton from '@/modules/rosettes/components/DynamicRosetteButton.vue';
import GameApi from '@/services/Api/gamification';
import StorageHelper from '@/utils/storageHelper';
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'Rosettes',
  components: {
    VueText,
    BrandButton,
    RosettePopup,
    BrandSurveyPopup,
    DynamicRosetteButton,
    NpsSurvey,
  },
  mixins: [RosetteInfo, gtmUtils],
  data() {
    return {
      selectedRosette: {},
      showRosetteModal: false,
      isNpsRosette: false,
      hasActiveMarathons: false,
      hasActiveMarathonsTriggered: false,
      hasNpsSurvey: false,
      npsSurveyId: 0,
    };
  },
  methods: {
    redirectToRosetteDetail() {
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.RosettesDetail.path}`,
      );
    },
    selectCurrentRosette(rosette) {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('rozet', {
        type: rosette.rosetteName,
      });
      this.selectedRosette = rosette;
      this.showRosetteModal = true;
      this.isNpsRosette = false;
    },
    openNpsSurveyPopup() {
      this.isNpsRosette = !this.isNpsRosette;
      this.showRosetteModal = false;
    },
    async closeRosetteModal() {
      this.isNpsRosette = false;
      await this.getNpsRosetteDetail();
      await this.getRosettesList();
      await this.setProgressValue();
    },
    async activeMarathons() {
      if (!this.hasActiveMarathonsTriggered) {
        const result = await GameApi.checkActiveMarathons();
        result?.data?.Data?.marathon?.forEach(f => {
          if (f.marathonName == 'Haftanın Bulmacası') {
            new StorageHelper({ id: 'PlayableInfo' }).set(f);
            this.hasActiveMarathons = true;
          }
        });
        this.hasActiveMarathonsTriggered = true;
      }
    },
    getNpsRosetteDetail() {
      Common.getNpsRosetteDetail().then(res => {
        if (res?.data?.Data) {
          this.npsSurveyId = res?.data?.Data.surveyId;
          this.hasNpsSurvey = !!res.data.Data.surveyId && res.data.Data.surveyId != 0;
        }
      });
    },
  },
  async updated() {
    await this.setProgressValue();
  },
  async mounted() {
    await this.setProgressValue();
    await this.getRosettesList();
    await this.activeMarathons();
  },
  watch: {
    getRosettesInfo: {
      handler() {
        this.checkNpsRosette(this.getNpsRosetteDetail);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.rosette-container {
  display: flex;
  flex-flow: column;
  padding: 0 10px 20px;
}
.rosette-box {
  display: flex;
  flex-wrap: wrap;
}
.rosette-item {
  display: flex;
  flex-flow: column;
  width: calc(20% - 20px);
  margin: 5px 10px;
  align-items: center;
  cursor: pointer;
  text-align: center;
}
.circular-progress {
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-radius: 8px;
  background: #fff;
  flex-direction: column;
  align-items: center;
}
.progress-bar {
  position: relative;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar::before {
  content: '';
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #fff;
}
.all-show-button {
  width: 35% !important;
  align-self: center;
  height: 40px !important;
  font-size: 8px !important;
  margin: 30px 0;
}
.rosette-image {
  position: absolute;
  height: 35px;
  width: 25px;
}

.dynamic-rosette-button {
  width: 80px;
}

.rosette-name {
  height: 54px;
  color: #23303d;
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
}
</style>
